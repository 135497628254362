import { getLBDiaryData, getLBData } from '@/api'
import cookies from 'js-cookie'

const ENDPOINT_MEALDETAIL = 'V1/MealDetail'
const ENDPOINT_MEALHIGHFIVE = 'V1/RecordHighFiveReg'
const ENDPOINT_MEALFAVORITE = 'V1/RecordFavoriteReg'
const ENDPOINT_MEAL_DELETE = 'V1/MealDelete'
const ENDPOINT_UPDATE_FEED_SHARE = 'V1/RecordFeedShareUpdate'
const ENDPOINT_CONTENTS_NOT_TO_BE_MISSED_FEED = 'Feed/ContentsNotToBeMissedFeed'

const defaultParams = {
  uId: cookies.get('memberCummunityUID'),
  appType: cookies.get('appType'),
  appVersion: cookies.get('appVersion'),
  languageCode: 'ko',
  countryCode: '82',
  whereToUseCode: 'FEED',
  cuid: 0,
  challengeId: 0,
  utcInterval: -540,
}

export default {
  namespaced: true,
  state: {
    mealRecordDetail: null,
    // contentsNotToBeMissedFeed: []
  },
  actions: {
    async getDiaryMealDetail({ state }, payload) {
      payload = {
        ...defaultParams,
        ...payload,
      }

      const resp = await getLBDiaryData(ENDPOINT_MEALDETAIL, 'post', payload)
      state.mealRecordDetail = resp.data
      return resp.data
    },
    async setRecordHighFive({ state }, payload) {
      payload = {
        ...defaultParams,
        ...payload,
      }

      const resp = await getLBDiaryData(ENDPOINT_MEALHIGHFIVE, 'post', payload)
      return resp.data
    },
    async setRecordFavorite({ state }, payload) {
      payload = {
        ...defaultParams,
        ...payload,
      }

      const resp = await getLBDiaryData(ENDPOINT_MEALFAVORITE, 'post', payload)
      return resp.data
    },
    async deleteMealDetail({ state }, payload) {
      payload = {
        ...defaultParams,
        ...payload,
      }

      const resp = await getLBDiaryData(ENDPOINT_MEAL_DELETE, 'post', payload)
      return resp.data
    },
    async updateFeedSgare({ state }, payload) {
      payload = {
        ...defaultParams,
        ...payload,
      }

      const resp = await getLBDiaryData(ENDPOINT_UPDATE_FEED_SHARE, 'post', payload)
      return resp.data
    }
  },
}
